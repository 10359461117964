<template>
    <div>
        <div class="mt-3 mb-10 vx-row">
            <header class="w-1/2 vx-col">
                <h4>{{ $t("resorts.maps.title") }}</h4>
                <p class="text-base">{{ $t("resorts.maps.mapupload") }}</p>
            </header>
            <div class="w-1/2 pt-2 text-right vx-col">
                <vs-button 
                    class="rounded-lg xs:w-full sm:w-auto" 
                    :to="{ name: 'add-map', params: { 'map' : {}, 'mapTypesCreated': this.mapTypesCreated } }" >
                    {{ $t("resorts.maps.add") }}
                </vs-button>
            </div>
        </div>
        <div>
            <Datatable 
                :config="datatableConfig"
                :elements="maps"
                :stripe="true"
                :draggable="false"
                :sst="false"
                :pagination="pagination"
                @action="action"
                @filter="filter"
            />
        </div>
    </div>
</template>

<script>

import MapService from '@/modules/Resorts/Services/MapService'
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import PaginationService from '@/modules/Shared/Services/PaginationService'

export default {
    name: 'maps',
    components: {
        Datatable
    },
    data () {
        return {
            resortUuid: '',
            maps: [],
            datatableConfig: [
                { 'type': 'bigimage', 'field': 'full_map_file', head: this.$t("resorts.maps.image"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'key_app_configurator', head: this.$t("resorts.maps.key"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'map_key', head: this.$t("resorts.maps.type"), 'searchable': false, 'sorteable': false },
                { 'type': 'actions', 'field': 'actions', head: this.$t("resorts.maps.actions"), 'searchable': false, 'sorteable': false }
            ],
            pagination: {},
        }
    },
    async created() {
        this.resortUuid = this.$route.params.uuid
        this.getMaps({})
    },
    methods: {
        async getMaps(parameters) {
            this.maps = await MapService.getMaps(this.resortUuid, parameters)
            this.pagination = {
                per_page: this.maps.per_page,
                total: this.maps.total
            }
            this.maps = this.maps.data.map(
                map => ({...map, 'actions': [ 'delete', 'edit' ]})
            )
        },
        filter(params) {
            this.getMaps(PaginationService.parseParameters(params))
        },
        action(type, uuid) {
            const map = this.maps.filter(map => map.uuid === uuid)[0];
            switch(type) {
                case 'edit':   
                    this.$router.push({
                        name: 'edit-map',
                        params: {
                            'mapUuid': map.uuid,
                            'map': map,
                            'mapTypesCreated': this.mapTypesCreated
                        }
                    });
                break;
                case 'delete':
                    this.deleteMap(uuid)
                break;
            }
        },
        async deleteMap(uuid) {
            this.$vs.loading()
            let response = await MapService.deleteMap(this.resortUuid, uuid)
            
            if(response.code == 200) {
                this.getMaps({})
                this.$vs.notify( { text: this.$t("resorts.maps.removedok"), color: 'success',position:'top-right' } )
            } else {
                this.$vs.notify( { text: this.$t("resorts.maps.removedko"), color: 'danger',position:'top-right'} )
            }

            this.$vs.loading.close()
        }
    },
    computed: {
        mapTypesCreated() {
            const typesCreated = this.maps ? this.maps.filter( (map) => map.map_key !== 'custom' ) : []
            return typesCreated.map( (map) => map.id_map_key )
        }
    }
}
</script>